<!--
 * @Description: 
 * @Author: 优
 * @Date: 2021-08-05 15:47:11
 * @LastEditors: 优
 * @LastEditTime: 2022-03-03 09:23:57
-->
<template>
  <div class="img-center">
        <!-- 面包屑 begin -->
        <eup-crumbs icon="CreditCard" firstCrumbs="资源管理" secondCrumbs="图片中心" />
        <!-- 面包屑 end -->
        <!-- 内容区域 begin -->
         <div class="container">
            <div class="handle-box">
                <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:resource:image:getlistgetpagelist`,`api${state.VIEW_VERSION}:resource:image:uploadimage`,`api${state.VIEW_VERSION}:resource:image:batchsoftdelete`])">
                  <template v-if="checkPermission([`api${state.VIEW_VERSION}:resource:image:getlistgetpagelist`])">
                    <el-form-item label="图片类型">
                      <el-select v-model="state.query.FileCategory" placeholder="图片类型" class="handle-select mr10" @change="handleSearch">
                        <el-option v-for="item in state.optionstypede" :key="item.FileCategory" :label="item.label" :value="item.FileCategory"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                  <el-form-item>
                  <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
                  <el-button type="primary" icon="Plus" @click="handleClickAddData" v-if="checkPermission([`api${state.VIEW_VERSION}:resource:image:uploadimage`])">新增</el-button>
                  <template v-if="checkPermission([`api${state.VIEW_VERSION}:resource:image:batchsoftdelete`])">
                    <el-button
                      type="danger"
                      icon="Delete"
                      class="handle-del mr10"
                      @click="handleClickBatchDelete()"
                      >批量删除</el-button>
                    <el-checkbox :indeterminate="state.isIndeterminate" v-model="state.stcheckAll" @change="handleCheckAllChange">全选</el-checkbox>
                  </template>
                  </el-form-item>
                </el-form>
              <div >
    <el-row  >
  <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" v-for="(item,index) in state.cities" :key="index">
    <dl class="res-pic"> 
        <dt class="box">  
          <el-image :src="item.filePath" class="res-img"  :preview-src-list="[item.filePath]" >
        </el-image>
          <el-icon class="icon" @click="handleChec(item.imgId)"><Delete/></el-icon>
        </dt>
      
        <dd>  <el-checkbox-group v-model="state.checkedCities" @change="handleCheckedCitiesChange">
    <el-checkbox  :label="item">{{item.fileName}} </el-checkbox>
  </el-checkbox-group></dd>
              </dl>
  </el-col>
         </el-row>
              
  </div>
            </div>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[24,48,96,192]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
        </div>
        <!-- 内容区域 end -->
                <!-- 添加/编辑窗口 begin -->
        <el-dialog 
          title="添加图片"
          v-model="state.addDialogFormVisible"
          :close-on-click-modal="false"
          width="30%"
          @close="closeDialog">
            <el-form
                ref="refAddForm"
                :model="state.addForm"
                label-width="80px"
                :inline="false"         
              >
              <el-row>
                    <el-form-item label="图片类型" prop="bannerType">
                      <el-select v-model="state.addForm.FileCategory" placeholder="请选择">
                            <el-option
                              v-for="item in state.optionstypedes"
                              :key="item.FileCategory"
                              :label="item.label"
                              :value="item.FileCategory"
                            ></el-option
                          ></el-select>
                    </el-form-item>
                   <el-form-item label="图片" prop="Portrait" >
                       <el-upload action="#"  list-type="picture-card"  :on-change="handleChange" :on-remove="handleRemove"  :on-success="haderOnsuucess" >
                         <img v-if="state.form.portraitUrl" :src="state.form.portraitUrl" style="width:100%;height:100%">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item> 
                <!-- </el-col> -->
              </el-row>
            </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="closeDialog()">取 消</el-button>
              <el-button type="primary" @click="addDialogFormSave(this)">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!-- 添加/编辑窗口 end -->
  </div>
</template>

<script>
import { reactive, onBeforeMount, onMounted } from 'vue'
import EupPagination from "../../../components/EupPagination.vue"
import {GetListGetPageList,ImageSoftDelete,ImageBatchSoftDelete} from "@/serviceApi/Image/Image"
import { ElMessage,ElMessageBox} from 'element-plus'
import {postimg} from "@/serviceApi/Image/Image"
import {elConfirmDialog} from "@/common/js/comm"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
 name: '',
components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },

  setup() {

    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
       pageIndex:1,pageSize:24,pageTotal:0,deposit:[],ptyr:[],stcheckAll:false,
       query: {
                FileCategory: '0',
            },
             optionstypede: [
              {
                FileCategory : '0', label: '全部'
              },
              {
                FileCategory : '100', label: '头像'
              },
              {
                FileCategory : '200', label: '文档'
              },
              {
                FileCategory : '300', label: '证件'
              },
              {
                FileCategory : '400', label: '聊天'
              },
              {
                FileCategory : '500', label: '建议反馈'
              }
            ],
             optionstypedes: [

              {
                FileCategory : '100', label: '头像'
              },
              {
                FileCategory : '200', label: '文档'
              },
              {
                FileCategory : '300', label: '证件'
              },
              {
                FileCategory : '400', label: '聊天'
              },
              {
                FileCategory : '500', label: '建议反馈'
              }
            ],
         checkAll: false,
        checkedCities: [],
        cities: [],
        isIndeterminate: false,addDialogFormVisible:false,
        addForm:{
          FileCategory:"",file:"",
        },
        form:{},
    })
    onBeforeMount(() => {
    })
    onMounted(() => {
      getData();
    })
    const driverTypeHandle=()=>{
    }
    const getData=()=>{
        var params={
            "currentPage":state.pageIndex,
          "pageSize": state.pageSize,
          "filter.imgCategory": state.query.FileCategory =='0'? null:state.query.FileCategory,
        }
        
        GetListGetPageList(params).then((res)=>{
                if(res.code==1){
              state.cities=res.data.list
             for (let index = 0; index <state.cities.length; index++) {
               state.ptyr.push(state.cities[index].id)
             }
              state.pageTotal = res.data.total;//初始化列表数据总数
                state.deposit=[];
                 state.stcheckAll=false;
                }else{
                   ElMessage.error(res.msg)
                }
        })
    };
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
            handleCheckAllChange(false);
        }


        const handleSearch=()=>{
          getData();
          state.pageIndex=1
        }
        const handleClickBatchDelete=()=>{
          if(state.deposit.length==0){
            return  ElMessage.error("请选择数据");
          }
          var paerms=[];
        for (let index = 0; index < state.deposit.length; index++) {
          paerms.push(state.deposit[index].imgId)          
        }
         elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
            ImageBatchSoftDelete(paerms).then(res =>{
                if (res.code == 1){
                state.stcheckAll=false;
                state.deposit=[];
                state.checkedCities={};
                state.isIndeterminate = false; 
                handleCheckAllChange(false);
                ElMessage.success("删除成功");
                   getData();
                } else {
                    ElMessage.error("删除失败！");
                }
            });
        }, ()=>{
            ElMessage.info("取消删除！");
        });
        }
        const handleClickAddData=()=>{
          state.addDialogFormVisible=true;
        }
         const handleCheckAllChange = (val) => {
         state.checkedCities = val ? state.cities : [];
         state.isIndeterminate = false;
        if(val){
           state.deposit=state.cities
        }
        else{
          state.deposit=[];
        }
      };
        const handleCheckedCitiesChange = (value) => {
         const checkedCount = value.length;
        state.checkAll = checkedCount === state.cities.length;
        state.isIndeterminate = checkedCount > 0 && checkedCount < state.cities.length; 
        state.deposit=value
      };
      const handleChec=(val)=>{
         elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
            ImageSoftDelete(val).then(res =>{
                if (res.code == 1){
                    ElMessage.success("删除成功");
                   getData();
                } else {
                    ElMessage.error("删除失败！");
                }
            });
        }, ()=>{
            ElMessage.info("取消删除！");
        });
      };
      //图片 begin
        //图片上传成功回调
        const haderOnsuucess=(response, file, fileList)=> {
            console.log(response, file, fileList);

        }
        const handleRemove=(file, fileList)=> {
            state.hideUpload = fileList.length >= state.limitCount;
            state.form.portraitUrl="";
        }
        const handleChange=(file, fileList)=> {
            state.hideUpload = fileList.length >= state.limitCount;
            state.form.portraitUrl=file.url;
            state.addForm.file=file
           
        }
        //图片 end  
         //关闭对话框
        const closeDialog = ()=>{
           state.addDialogFormVisible = false;
           state.addForm.FileCategory=""
           state.form.portraitUrl="";
        };
         const addDialogFormSave=()=>{
           if(state.addForm.FileCategory==""){
             return ElMessage.error("图片类型不能为空")
           }
           if( state.addForm.file==""){
             return ElMessage.error("图片不能为空")
           }         
          var formData = new FormData();
            formData.append('File', state.addForm.file.raw);
            formData.append('FileCategory', state.addForm.FileCategory);
            postimg(formData).then(function(res) {
              if(res.code==1){
                ElMessage.success("添加成功")
                 getData();
                  state.addForm.file.raw="";
                 state.addDialogFormVisible=false;
                }
                else{
                  ElMessage.error(res.msg)
                }
            }); 
         }
    return {
     state,driverTypeHandle,resPageData,getData,handleSearch,handleClickBatchDelete,handleClickAddData,handleCheckAllChange,
     handleCheckedCitiesChange,handleChec,closeDialog,haderOnsuucess,handleChange,handleRemove,addDialogFormSave
    }
  },
}

</script>

<style scoped >

.img-center .box {
		font-family: 'Ubuntu', sans-serif;
		overflow: hidden;
		position: relative;
		transition: all 0.3s;
	}
.img-center .box:hover {
		box-shadow: 0 0 15px -5px #000;
	}
	.img-center .box:before {
		content: '';
		background-color: transparent;
		background-size: 30px 30px;

		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		transition: all 0.3s ease 0.2s;
	}
.img-center .box:hover:before {
		opacity: 1;
	}
.img-center .box img {
		width: 100%;
		height: auto;
		transition: all 0.3s ease 0s;
	}
.img-center .box:hover img {
		filter: blur(2px);
	}
.img-center .box .box-content {
		color: #fff;
		background-color: rgba(0,0,0,0.6);
		text-align: center;
		width: 100%;
		height: 59%;
		padding: 18% 0 0 0;
		position: absolute;
		left: 0;
		top: -100%;
		z-index: 2;
		transition: all 0.3s ease 0s;
	}
	
.img-center .box .title {
		color: #fff;
		font-size: 23px;
		font-weight: 600;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin: 0;
	}
.img-center .box .post {
		color: #32c287;
		font-size: 14px;
		font-style: italic;
		text-transform: capitalize;
		letter-spacing: 2px;
	}
.img-center .box .icon {
		background: rgba(243, 245, 244, 0.6);
		text-align: center;
		height: 39%;
		width: 100%;
		padding: 15px 0 0;
		margin: 0;
		list-style: none;
		transform: translateX(50%);
		position: absolute;
		right: 50%;
    font-size: 1.5rem;
		bottom: -100%;
		z-index: 1;
		transition: all 0.3s;
	}
	.box:hover .icon {
		bottom: 0;
	}
.img-center .box .icon li {
		display: inline-block;
		margin: 0 3px;
	}
.img-center .box .icon li a {
		color: #32c287;
		background-color: #fff;
		font-size: 18px;
		line-height: 40px;
		height: 40px;
		width: 40px;
		border-radius: 40%;
		display: block;
		transition: all 0.3s;
	}
.img-center .box .icon li a:hover {
		color: #fff;
		background-color: #04a562;
		box-shadow: 0 0 0 3px rgba(255,255,255,0.5), 0 0 5px rgba(0,0,0,0.5) inset;
	}

	@media only screen and (max-width:990px) {
.img-center .box {
		margin-bottom: 30px;
	}
	}
	@media only screen and (max-width:479px) {
.img-center .box .title {
		font-size: 20px;
	}
  }
</style>